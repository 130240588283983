import React, { createContext, useReducer } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const isBrowser = () => typeof window !== `undefined`

const getLocalValue = (id) => {
  if (isBrowser()) {
    const localValue = JSON.parse(localStorage.getItem(id))

    if (localValue) {
      return true
    } else {
      return localValue
    }
  } else {
    return true
  }
}

const getTheme = () => {
  if (isBrowser()) {
    const theme = JSON.parse(localStorage.getItem(`theme`))

    if (!theme) {
      if (window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`).matches) {
        return `dark`
      } else {
        return `light`
      }
    } else {
      return theme
    }
  } else {
    return `light`
  }
}

const initialState = {
  blueprintMode: getLocalValue(`blueprintMode`),
  codeTab: getLocalValue(`codeTab`),
  theme: getTheme(),
}

function reducer(state, action) {
  switch (action.type) {
    case `TOGGLE_BLUEPRINT_MODE`: {
      return {
        ...state,
        blueprintMode: !state.blueprintMode,
      }
    }
    case `TOGGLE_CODE_TAB`: {
      return {
        ...state,
        codeTab: !state.codeTab,
      }
    }
    case `TOGGLE_THEME`: {
      return {
        ...state,
        theme: state.theme === `light` ? `dark` : `light`,
      }
    }
    default:
      throw new Error(`Bad Action Type`)
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
